export default {

  name: 'custom-events',

  initialize(application) {
    application.customEvents = application.customEvents || {};
    application.customEvents.wheel = 'wheel';
  },

};
